<template>
  <div>
    <top-nav-bar></top-nav-bar>
    <div class="page position-absolute">
      <section class="container">
        <div class="my-2" v-html="markDown"></div>
      </section>
      <section class="container">
        <div class="row">
          <div
            class="col-6 col-md-2 logo"
            style="background-image: url('./assets/images/BINGO-Logo.jpg')"
          ></div>
          <div
            class="col-6 col-md-2 logo"
            style="background-image: url('./assets/images/logo TGG.png')"
          ></div>
          <div
            class="col-12 col-md-4 logo"
            style="background-image: url('./assets/images/hbs.jpg')"
          ></div>
          <div
            class="col-12 col-md-4 logo"
            style="background-image: url('./assets/images/landfrauen.gif')"
          ></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import marked from "marked";
import aboutUs from "./aboutus.md";
export default {
  inject: ["activePage"],
  computed: {
    markDown() {
      return marked(aboutUs);
    },
  },
  created() {
    this.activePage.value = this.$routeNames.routeAboutPage;
  },
};
</script>

<style scoped>
.logo {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 60px;
  margin: 20px 0px;
}
</style>
